import React from "react";
import {Helmet} from "react-helmet";

import Navbar from "../components/navbar.js";
import Footer from "../components/footer.js";
import "../css/dienstleistungen.css"
import favicon from '../assets/favicon.ico';
import autoschmal from "../images/autoschmal.png";
import lastwagenschmal from "../images/lastwagenschmal.png";
import landwirtschaftschmal from "../images/landwirtschaftschmal.png";
import staplerschmal from "../images/staplerschmal.png";
import serviceschmal from "../images/serviceschmal.png";

const DienstleistungenPage = () => {
  return (
    <div>
      <Helmet>
        <title>Pneuhaus Rogenmoser</title>
        <meta charset="utf-8" />
        <meta name="description" content="Willkommen bei Pneuhaus Rogenmoser in Auw. Ihr Reifenhändler in der Region Freiamt." />
        <meta name="keywords" content="Pneu,Pneuservice,Pneuverkauf,Pneuhandel,Reifenservice,Reifenhandel,Auw,Muri,Merenschwand,Sins,Freiamt,Aargau,Reifen," />
        <meta name="robots" content="noindex" />
        <meta name="author" content="Melanie Rogenmoser"/>
        <meta name="copyright" content="Melanie Rogenmoser"/>
        <meta name="date" content="2022-08-09"/>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <Navbar />

           <div className="dienstleistungtext">

            <div id="personenwagen" className="textfeld">
            <img className="picturedienstleistungen" src={autoschmal} alt="Bild Reifen wechseln" />
              <h1>Personenwagen</h1>
              <h2>Was wir anbieten:</h2>
              <h5>Bestellen von neuen Reifen, Felgen, ect.</h5>
              <h5>Neue Reifen auf Felgen + Auswuchten + Montage</h5>
              <h5>Radmontage</h5>
              <h5>Reifen einlagern und waschen</h5>
              <h5>Reifen Reperaturen</h5>
              <h6>Bei Fragen zu spezifischen Marken und Grössen beraten wir Sie gerne persönlich.</h6>
            </div>

            <div id="lastwagen" className="textfeld">
            <img className="picturedienstleistungen" src={lastwagenschmal} alt="Bild Reifen wechseln" />
              <h1>Lastwagen</h1>
              <h2>Was wir anbieten:</h2>
              <h5>Bestellen von neuen Reifen, Felgen, ect.</h5>
              <h5>Neue Reifen auf Felgen + Auswuchten + Montage</h5>
              <h5>Radmontage</h5>
              <h6>Bei Fragen zu spezifischen Marken und Grössen beraten wir Sie gerne persönlich.</h6>
            </div>

            <div id="landwirtschaft" className="textfeld">
            <img className="picturedienstleistungen" src={landwirtschaftschmal} alt="Bild Reifen wechseln" />
              <h1>Landwirtschaft</h1>
              <h2>Was wir anbieten:</h2>
              <h5>Bestellen von neuen Reifen, Felgen, ect.</h5>
              <h5>Neue Reifen auf Felgen + Auswuchten + Montage</h5>
              <h5>Radmontage</h5>
              <h5>Reifen Reperaturen</h5>
              <h6>Bei Fragen zu spezifischen Marken und Grössen beraten wir Sie gerne persönlich.</h6>
            </div>

            <div id="stapler" className="textfeld">
            <img className="picturedienstleistungen" src={staplerschmal} alt="Bild Reifen wechseln" />
              <h1>Stapler</h1>
              <h2>Was wir anbieten:</h2>
              <h5>Bestellen von neuen Reifen, Felgen, ect.</h5>
              <h5>Neue Reifen auf Felgen aufziehen</h5>
              <h5>Radmontage</h5>
              <h6>Bei Fragen zu spezifischen Marken und Grössen beraten wir Sie gerne persönlich.</h6>
            </div>

            <div id="service" className="textfeld">
            <img className="picturedienstleistungen" src={serviceschmal} alt="Bild Reifen wechseln" />
              <h1>Service</h1>
              <h2>Was wir anbieten:</h2>
              <h5>PKW Reparaturen</h5>
              <h5>Service für MFK</h5>
              <h6>Wir haben ein grosses Know-How von diversen Fahrzeugen und beraten Sie gerne persönlich mit Ihrem Anliegen.</h6>

            </div>
            </div>
      <Footer/>
    </div>
  );
};

export default DienstleistungenPage;